import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaThumbsUp } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { buscarCep, data, dataMysql, extract } from '../../../util/util';
import ReactSelect from '../../../components/ReactSelect';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import moment from 'moment';
import Data from '../../../components/Data';

export default function CadastroEventoParticipanteConvite({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({ evento: { camposQuestionario: {} } });
    const history = useHistory();
    let { state } = useLocation();
    //var idEvento = state?.evento ?? null;

    const preencherCep = (cep, dadosAtuais) => {
        buscarCep(cep).then((resposta) => {
            if (!resposta.erro) {
                setDados({
                    ...dadosAtuais,
                    ...{
                        enderecobairro: resposta.bairro,
                        enderecocomplemento: resposta.complemento,
                        enderecologradouro: resposta.logradouro,
                        enderecopais: "30",
                        nomeEnderecopais: "Brasil",
                        enderecomunicipio: resposta.ibge,
                        nomeEnderecomunicipio: resposta.localidade
                    }
                });
            }
        });
    }

    const buscar = () => {
        api({
            method: "get",
            action: '/participante/' + id,
            token: token,
        }).then(response => {
            //extrai dados internos para o array principal
            var dadosRecebidos = extract({
                jsonLaravel: response.data.data,
                chaves: [
                    'enderecopais',
                    'aereomunicipioembarque',
                    'enderecomunicipio',
                    'banco',
                ]
            });

            delete dadosRecebidos.nome;
            dadosRecebidos.datanascimento = data(dadosRecebidos.datanascimento);

            dadosRecebidos.evento.camposQuestionario = dadosRecebidos.evento.camposQuestionario ?
                JSON.parse(dadosRecebidos.evento.camposQuestionario) :
                {
                    "tipoparticipante": "sim",
                    "nome": "sim",
                    "assinaturaListaPresenca": "sim",
                    "telefone": "sim",
                    "email": "sim",
                    "especialidade": "sim",
                    "rg": "sim",
                    "crm": "sim",
                    "crmuf": "sim",
                    "datanascimento": "sim",
                    "cpf": "sim",
                    "enderecologradouro": "sim",
                    "endereconumero": "sim",
                    "enderecocomplemento": "sim",
                    "enderecocep": "sim",
                    "enderecobairro": "sim",
                    "aereomunicipioembarque": "sim",
                    "enderecomunicipionome": "sim",
                    "enderecomunicipiouf": "sim",
                };

            if (dadosRecebidos.pcd) {
                dadosRecebidos.pcdCheck = true;
            }

            if (dadosRecebidos.restricaoalimentar) {
                dadosRecebidos.restricaoalimentarCheck = true;
            }

            setDados(dadosRecebidos);
        }).catch(err => {
            //console.log(err)
        });
    };//buscar

    const validar = async () => {
        if ((dados.evento.camposQuestionario.nome ?? "nao") === "sim") {
            var nome = document.querySelector("#nome");
            if ((dados.nome ?? '') === '') {
                toast.error('É necessário preencher o nome');
                setTimeout(() => {
                    nome.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.contato ?? "nao") === "sim") {
            var contato = document.querySelector("#contato");
            if ((dados.contato ?? '') === '') {
                toast.error('É necessário preencher o Nome para Crachá');
                setTimeout(() => {
                    contato.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.celular ?? "nao") === "sim") {
            var celular = document.querySelector("#celular");
            if ((dados.celular ?? '') === '') {
                toast.error('É necessário preencher o celular');
                setTimeout(() => {
                    celular.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.telefone ?? "nao") === "sim") {
            var telefone = document.querySelector("#telefone");
            if ((dados.telefone ?? '') === '') {
                toast.error('É necessário preencher o Telefone');
                setTimeout(() => {
                    telefone.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.email ?? "nao") === "sim") {
            var email = document.querySelector("#email");
            if ((dados.email ?? '') === '') {
                toast.error('É necessário preencher o E-mail');
                setTimeout(() => {
                    email.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.rg ?? "nao") === "sim") {
            var rg = document.querySelector("#rg");
            if ((dados.rg ?? '') === '') {
                toast.error('É necessário preencher o rg');
                setTimeout(() => {
                    rg.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.cpf ?? "nao") === "sim") {
            var cpf = document.querySelector("#cpf");
            if ((dados.cpf ?? '') === '') {
                toast.error('É necessário preencher o cpf');
                setTimeout(() => {
                    cpf.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.crm ?? "nao") === "sim") {
            var crm = document.querySelector("#crm");
            if ((dados.crm ?? '') === '') {
                toast.error('É necessário preencher o CRM');
                setTimeout(() => {
                    crm.focus();
                }, 500);
                return false;
            }
        }


        if ((dados.evento.camposQuestionario.crmuf ?? "nao") === "sim") {
            var crmuf = document.querySelector("#crmuf");
            if ((dados.crmuf ?? '') === '') {
                toast.error('É necessário preencher o Estado do CRM');
                setTimeout(() => {
                    crmuf.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.datanascimento ?? "nao") === "sim") {
            var datanascimento = document.querySelector("#datanascimento");
            if ((dados.datanascimento ?? '') === '') {
                toast.error('É necessário preencher a Data de Nascimento');
                setTimeout(() => {
                    datanascimento.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.especialidade ?? "nao") === "sim") {
            var especialidade = document.querySelector("#especialidade");
            if ((dados.especialidade ?? '') === '') {
                toast.error('É necessário preencher a Especialidade');
                setTimeout(() => {
                    especialidade.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.instituicao ?? "nao") === "sim") {
            var instituicao = document.querySelector("#instituicao");
            if ((dados.instituicao ?? '') === '') {
                toast.error('É necessário preencher a instituicao');
                setTimeout(() => {
                    instituicao.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.enderecocep ?? "nao") === "sim") {
            var enderecocep = document.querySelector("#enderecocep");
            if ((dados.enderecocep ?? '') === '') {
                toast.error('É necessário preencher o CEP');
                setTimeout(() => {
                    enderecocep.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.enderecologradouro ?? "nao") === "sim") {
            var enderecologradouro = document.querySelector("#enderecologradouro");
            if ((dados.enderecologradouro ?? '') === '') {
                toast.error('É necessário preencher a rua');
                setTimeout(() => {
                    enderecologradouro.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.endereconumero ?? "nao") === "sim") {
            var endereconumero = document.querySelector("#endereconumero");
            if ((dados.endereconumero ?? '') === '') {
                toast.error('É necessário preencher o número');
                setTimeout(() => {
                    endereconumero.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.enderecobairro ?? "nao") === "sim") {
            var enderecobairro = document.querySelector("#enderecobairro");
            if ((dados.enderecobairro ?? '') === '') {
                toast.error('É necessário preencher o bairro');
                setTimeout(() => {
                    enderecobairro.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.enderecomunicipio ?? "nao") === "sim") {
            var enderecomunicipio = document.querySelector("#enderecomunicipio");
            if ((dados.enderecomunicipio ?? '') === '') {
                toast.error('É necessário preencher o Município');
                setTimeout(() => {
                    enderecomunicipio.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.aereo ?? "nao") === "sim") {
            if ((dados.evento.camposQuestionario.aereomunicipioembarque ?? "nao") === "sim") {
                var aereomunicipioembarque = document.querySelector("#aereomunicipioembarque");
                if ((dados.aereomunicipioembarque ?? '') === '') {
                    toast.error('É necessário preencher a Cidade de Embarque');
                    setTimeout(() => {
                        aereomunicipioembarque.focus();
                    }, 500);
                    return false;
                }
            }
        }

        if ((dados.evento.camposQuestionario.enderecouf ?? "nao") === "sim") {
            var enderecouf = document.querySelector("#enderecouf");
            if ((dados.enderecouf ?? '') === '') {
                toast.error('É necessário preencher o Estado');
                setTimeout(() => {
                    enderecouf.focus();
                }, 500);
                return false;
            }
        }

        if ((dados.evento.camposQuestionario.necessidadeespecial ?? "nao") === "sim") {
            if ((dados.restricaoalimentarCheck ?? false) === true) {
                var restricaoalimentar = document.querySelector("#restricaoalimentar");
                if ((dados.restricaoalimentar ?? '') === '') {
                    toast.error('É necessário preencher Restrição Alimentar');
                    setTimeout(() => {
                        restricaoalimentar.focus();
                    }, 500);
                    return false;
                }
            }
            if ((dados.pcdCheck ?? false) === true) {
                var pcd = document.querySelector("#pcd");
                if ((dados.pcd ?? '') === '') {
                    toast.error('É necessário preencher PCD');
                    setTimeout(() => {
                        pcd.focus();
                    }, 500);
                    return false;
                }
            }
        }


        for (let i = 1; i <= 10; i++) {
            if ((dados.evento.camposQuestionario[`coluna${i}`] ?? "nao") === "sim") {
                const coluna = document.querySelector(`#coluna${i}`);
                if ((dados[`coluna${i}`] ?? '') === '') {
                    toast.error(`É necessário preencher ${dados.evento?.[`nomecoluna${i}`] ?? `Coluna ${i}`}`);
                    setTimeout(() => {
                        coluna?.focus();
                    }, 500);
                    return false;
                }
            }
        }

        return true;
    };

    const salvar = async () => {
        if (!(await validar())) {
            return false; //para codigo aqui
        }

        var idEvento = dados.evento.id;
        let dadosSalvar = { ...dados }; //faz alterações antes de enviar para salvar

        if (dadosSalvar.datanascimento) {
            dadosSalvar.datanascimento = dataMysql(dadosSalvar.datanascimento);
        }

        dadosSalvar.dataconfirmacaodados = moment().format('YYYY-MM-DD'); //data em que confirmou os dados

        delete dadosSalvar.restricaoalimentarCheck;
        delete dadosSalvar.pcdCheck;
        delete dadosSalvar.hoteldatacheckin;
        delete dadosSalvar.hoteldatacheckout;
        delete dadosSalvar.dataopcaoaereo;
        delete dadosSalvar.datacartainformativa;
        delete dadosSalvar.dataconfirmacaocartainformativa;
        delete dadosSalvar.datacartaconvite; //data em que a carta foi enviada
        delete dadosSalvar.datacartaassinada;
        delete dadosSalvar.dataescolhaaereo;
        delete dadosSalvar.datainscricao;

        delete dadosSalvar.tiposla;
        delete dadosSalvar.evento;
        delete dadosSalvar.hotelmunicipio;
        delete dadosSalvar.cargo;
        delete dadosSalvar.categoria;
        delete dadosSalvar.logomarca;
        delete dadosSalvar.tipoparticipante;

        api({
            method: 'put',
            action: '/participante/' + id,
            token: token,
            data: {
                ...dadosSalvar,
                ...{ dadosConfirmados: true }, //enviar email para Coordenadorrsvp->emailsecundario
            }
        })
            .then(function (response) {
                if (dadosSalvar.aereo === 'sim') {
                    history.push("/cadastroEventoParticipanteAereoConvite/" + id);
                    return;
                }
                if (dadosSalvar.transfer === 'sim' || dadosSalvar.transferextra === 'sim') {
                    history.push("/cadastroEventoParticipanteTransferConvite/" + id);
                    return;
                }
                if (dadosSalvar.hotelhospedagem === 'sim') {
                    history.push("/cadastroEventoParticipanteHotelConvite/" + id);
                    return;
                }
                history.push("/cadastroEventoParticipanteConviteConcluido/" + idEvento);
            })
            .catch(function (error) {
                //alert(error)
            });
    }; //salvar

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp', 'cliente'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Confirmação de dados</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                {(dados.evento?.camposQuestionario?.nome ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                                value={dados.nome ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Nome Completo (conforme o seu documento)</label>
                                                        </div>
                                                        <div><strong>* caso tenha Aéreo favor se atentar, o nome deve ser igual documento de embarque</strong></div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.contato ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="contato" placeholder="Nome para o Crachá"
                                                                value={dados.contato ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "contato": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Nome para o Crachá</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.celular ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <InputMask mask="(99) 99999-9999" className="form-control form-control-lg" id="celular" placeholder="Celular"
                                                                value={dados.celular ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "celular": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Celular</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.telefone ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <InputMask mask="(99) 99999-9999" className="form-control form-control-lg" id="telefone" placeholder="Telefone"
                                                                value={dados.telefone ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "telefone": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Telefone</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.email ?? "nao") === "sim" ?
                                                    <div className="col-sm-8">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="email" placeholder="E-mail"
                                                                value={dados.email ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "email": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">E-mail</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.rg ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="rg" placeholder="RG"
                                                                value={dados.rg ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "rg": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">RG</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.cpf ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <InputMask mask="999.999.999-99" className="form-control form-control-lg" id="cpf" placeholder="CPF"
                                                                value={dados.cpf ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "cpf": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">CPF</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.crm ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <InputMask
                                                                className="form-control form-control-lg"
                                                                id="crm"
                                                                mask="9999999"
                                                                placeholder="CRM"
                                                                value={dados.crm ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "crm": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">CRM</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.crmuf ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Estado do CRM</label>
                                                            <ReactSelect
                                                                id={"crmuf"}
                                                                value={{ value: dados.crmuf ?? '', label: dados.crmuf ?? '' }}
                                                                onChange={e => setDados({ ...dados, ...{ "crmuf": e.value, "nomeUf": e.label } })}
                                                                options={[
                                                                    { value: "AC", label: "AC" },
                                                                    { value: "AL", label: "AL" },
                                                                    { value: "AP", label: "AP" },
                                                                    { value: "AM", label: "AM" },
                                                                    { value: "BA", label: "BA" },
                                                                    { value: "CE", label: "CE" },
                                                                    { value: "DF", label: "DF" },
                                                                    { value: "ES", label: "ES" },
                                                                    { value: "GO", label: "GO" },
                                                                    { value: "MA", label: "MA" },
                                                                    { value: "MT", label: "MT" },
                                                                    { value: "MS", label: "MS" },
                                                                    { value: "MG", label: "MG" },
                                                                    { value: "PA", label: "PA" },
                                                                    { value: "PB", label: "PB" },
                                                                    { value: "PR", label: "PR" },
                                                                    { value: "PE", label: "PE" },
                                                                    { value: "PI", label: "PI" },
                                                                    { value: "RJ", label: "RJ" },
                                                                    { value: "RN", label: "RN" },
                                                                    { value: "RS", label: "RS" },
                                                                    { value: "RO", label: "RO" },
                                                                    { value: "RR", label: "RR" },
                                                                    { value: "SC", label: "SC" },
                                                                    { value: "SP", label: "SP" },
                                                                    { value: "SE", label: "SE" },
                                                                    { value: "TO", label: "TO" },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.datanascimento ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <Data
                                                            id={"datanascimento"}
                                                            label="Data de Nascimento"
                                                            value={dados.datanascimento ?? ''}
                                                            onChange={date => setDados({ ...dados, ...{ "datanascimento": date } })}
                                                        />
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.especialidade ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="especialidade" placeholder="Especialidade"
                                                                value={dados.especialidade ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "especialidade": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Especialidade</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.instituicao ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="instituicao" placeholder="Instituição"
                                                                value={dados.instituicao ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "instituicao": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Instituição</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.enderecocep ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="enderecocep" placeholder="CEP"
                                                                value={dados.enderecocep ?? ''}
                                                                onChange={(e) => {
                                                                    dados.enderecocep = e.target.value;
                                                                    preencherCep(e.target.value, dados);
                                                                    setDados({ ...dados });
                                                                }}
                                                            />
                                                            <label htmlFor="floatingInput">CEP</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.enderecologradouro ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="enderecologradouro" placeholder="Rua / Logradouro"
                                                                value={dados.enderecologradouro ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "enderecologradouro": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Rua / Logradouro</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.endereconumero ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="endereconumero" placeholder="Número"
                                                                value={dados.endereconumero ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "endereconumero": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Número</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.enderecocomplemento ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="enderecocomplemento" placeholder="Complemento"
                                                                value={dados.enderecocomplemento ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "enderecocomplemento": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Complemento</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.enderecobairro ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-floating form-group">
                                                            <input type="text" className="form-control form-control-lg" id="enderecobairro" placeholder="Bairro"
                                                                value={dados.enderecobairro ?? ''}
                                                                onChange={e => setDados({ ...dados, ...{ "enderecobairro": e.target.value } })}
                                                            />
                                                            <label htmlFor="floatingInput">Bairro</label>
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.enderecomunicipio ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Cidade</label>
                                                            <ReactSelect
                                                                value={{ value: dados.enderecomunicipio ?? '', label: dados.nomeEnderecomunicipio ?? '' }}
                                                                onChange={e => setDados({ ...dados, ...{ "enderecomunicipio": e.value, "nomeEnderecomunicipio": e.label } })}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/municipio',
                                                                        token: token,
                                                                        params: {
                                                                            nome: input,
                                                                            uf: dados.enderecouf ?? ''
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                            return options;
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.enderecouf ?? "nao") === "sim" ?
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Estado</label>
                                                            <ReactSelect
                                                                id={"enderecouf"}
                                                                value={{ value: dados.enderecouf ?? '', label: dados.enderecouf ?? '' }}
                                                                onChange={e => setDados({ ...dados, ...{ "enderecouf": e.value, "nomeUf": e.label } })}
                                                                options={[
                                                                    { value: "AC", label: "AC" },
                                                                    { value: "AL", label: "AL" },
                                                                    { value: "AP", label: "AP" },
                                                                    { value: "AM", label: "AM" },
                                                                    { value: "BA", label: "BA" },
                                                                    { value: "CE", label: "CE" },
                                                                    { value: "DF", label: "DF" },
                                                                    { value: "ES", label: "ES" },
                                                                    { value: "GO", label: "GO" },
                                                                    { value: "MA", label: "MA" },
                                                                    { value: "MT", label: "MT" },
                                                                    { value: "MS", label: "MS" },
                                                                    { value: "MG", label: "MG" },
                                                                    { value: "PA", label: "PA" },
                                                                    { value: "PB", label: "PB" },
                                                                    { value: "PR", label: "PR" },
                                                                    { value: "PE", label: "PE" },
                                                                    { value: "PI", label: "PI" },
                                                                    { value: "RJ", label: "RJ" },
                                                                    { value: "RN", label: "RN" },
                                                                    { value: "RS", label: "RS" },
                                                                    { value: "RO", label: "RO" },
                                                                    { value: "RR", label: "RR" },
                                                                    { value: "SC", label: "SC" },
                                                                    { value: "SP", label: "SP" },
                                                                    { value: "SE", label: "SE" },
                                                                    { value: "TO", label: "TO" },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div> : null}
                                                {(((dados.aereo ?? "nao") === "sim") && ((dados.evento?.camposQuestionario?.aereomunicipioembarque ?? "nao") === "sim")) ?
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Cidade de Embarque</label>
                                                            <ReactSelect
                                                                value={{ value: dados.aereomunicipioembarque ?? '', label: dados.nomeAereomunicipioembarque ?? '' }}
                                                                onChange={e => setDados({ ...dados, ...{ "aereomunicipioembarque": e.value, "nomeAereomunicipioembarque": e.label } })}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/municipio',
                                                                        token: token,
                                                                        params: {
                                                                            nome: input,
                                                                            uf: dados.enderecouf ?? ''
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                            return options;
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div> : null}
                                                {(dados.evento?.camposQuestionario?.necessidadeespecial ?? "nao") === "sim" ?
                                                    <>
                                                        <hr />
                                                        <p>Caso tenha alguma necessidade especial, favor sinalizar abaixo:</p>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <div className="d-flex flex-column">
                                                                    <div className="mb-2">
                                                                        <input type="checkbox"
                                                                            id="restricaoalimentarCheck"
                                                                            checked={dados.restricaoalimentarCheck ?? false}
                                                                            onChange={e => {
                                                                                const isChecked = e.target.checked;
                                                                                setDados({
                                                                                    ...dados,
                                                                                    restricaoalimentarCheck: isChecked,
                                                                                    restricaoalimentar: isChecked ? dados.restricaoalimentar : ''
                                                                                });
                                                                            }}
                                                                            className="me-2"
                                                                        />
                                                                        <label htmlFor="restricaoalimentarCheck">Restrição Alimentar</label>
                                                                    </div>
                                                                    <input type="text" className="form-control form-control-lg" id="restricaoalimentar" placeholder="Especifique a restrição alimentar"
                                                                        value={dados.restricaoalimentar ?? ''}
                                                                        onChange={e => {
                                                                            const value = e.target.value;
                                                                            setDados({
                                                                                ...dados,
                                                                                restricaoalimentar: value,
                                                                                restricaoalimentarCheck: value ? true : dados.restricaoalimentarCheck
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <div className="d-flex flex-column">
                                                                    <div className="mb-2">
                                                                        <input type="checkbox"
                                                                            id="pcdCheck"
                                                                            checked={dados.pcdCheck ?? false}
                                                                            onChange={e => {
                                                                                const isChecked = e.target.checked;
                                                                                setDados({
                                                                                    ...dados,
                                                                                    pcdCheck: isChecked,
                                                                                    pcd: isChecked ? dados.pcd : ''
                                                                                });
                                                                            }}
                                                                            className="me-2"
                                                                        />
                                                                        <label htmlFor="pcdCheck">PCD</label>
                                                                    </div>
                                                                    <input type="text" className="form-control form-control-lg" id="pcd" placeholder="Especifique a necessidade especial"
                                                                        value={dados.pcd ?? ''}
                                                                        onChange={e => {
                                                                            const value = e.target.value;
                                                                            setDados({
                                                                                ...dados,
                                                                                pcd: value,
                                                                                pcdCheck: value ? true : dados.pcdCheck
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>
                                                    : null}
                                                {[...Array(10)].map((_, i) => {
                                                    const coluna = `coluna${i + 1}`;
                                                    const nomeColuna = `nomecoluna${i + 1}`;
                                                    return (dados.evento?.camposQuestionario?.[coluna] ?? "nao") === "sim" ? (
                                                        <div className="col-sm-4" key={i}>
                                                            <div className="form-floating form-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-lg"
                                                                    id={coluna}
                                                                    placeholder={dados.evento?.[nomeColuna] ?? `Coluna ${i + 1}`}
                                                                    value={dados[coluna] ?? ''}
                                                                    onChange={e => setDados({ ...dados, [coluna]: e.target.value })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.evento?.[nomeColuna] ?? `Coluna ${i + 1}`}</label>
                                                            </div>
                                                        </div>
                                                    ) : null;
                                                })}
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Confirmar dados</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </ErpTemplate >
    );
}