import React, { useEffect, useState } from 'react';
import { GiPlainCircle } from 'react-icons/gi';
import api from '../services/api';

export default function ParticipantesConfirmados({ evento, datalista, tipoListaPresenca }) {
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});
    const buscar = () => {
        //busca dados do evento
        api({
            token: token,
            method: 'get',
            action: '/evento/participantesConfirmados/' + evento + '?datalista=' + datalista + '&tipoListaPresenca=' + tipoListaPresenca,
        })
            .then(response => {
                setDados(response.data.data)
                //console.log(response.data.data);
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => {
        buscar();
    },
        // eslint-disable-next-line
        [datalista, tipoListaPresenca]);

    const totalExcel = dados["excel"] !== undefined ? dados["excel"].reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.total), 0) : 0;
    const presentesExcel = dados["excel"] !== undefined ? dados["excel"].reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.presentes), 0) : 0;
    const ausentesExcel = totalExcel - presentesExcel;

    const totalEvento = dados["evento"] !== undefined ? dados["evento"].reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.total), 0) : 0;
    const presentesEvento = dados["evento"] !== undefined ? dados["evento"].reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.presentes), 0) : 0;
    const ausentesEvento = totalEvento - presentesEvento;

    return (
        <section id="multiple-column-form">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <table width={"100%"}>
                                <tbody>
                                    <tr>
                                        <td colSpan={4} style={{ textAlign: "center", fontWeight: 'bold' }}>RSVP</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", width: "25%" }}>Tipo</td>
                                        <td style={{ textAlign: "center", width: "25%" }}>Total</td>
                                        <td style={{ textAlign: "center", width: "25%", backgroundColor: "#EEFFFF" }}>Presentes</td>
                                        <td style={{ textAlign: "center", width: "25%", backgroundColor: "#FFEEFF" }}>Ausentes</td>
                                    </tr>
                                    {dados["excel"] !== undefined ? dados["excel"].map((elemento, index) => (
                                        <tr>
                                            <td style={{ textAlign: "center" }}>{elemento.tipoparticipante}</td>
                                            <td style={{ textAlign: "center" }}>{elemento.total}</td>
                                            <td style={{ textAlign: "center", backgroundColor: "#EEFFFF" }}>{elemento.presentes}</td>
                                            <td style={{ textAlign: "center", backgroundColor: "#FFEEFF" }}>{elemento.total - elemento.presentes}</td>
                                        </tr>
                                    )) : null}
                                    {(dados["excel"] !== undefined && dados["excel"].length > 1) ? <tr style={{ backgroundColor: "#EEEEEE" }}>
                                        <td style={{ textAlign: "center" }}>Total</td>
                                        <td style={{ textAlign: "center" }}>{totalExcel}</td>
                                        <td style={{ textAlign: "center" }}>{presentesExcel}</td>
                                        <td style={{ textAlign: "center" }}>{ausentesExcel}</td>
                                    </tr> : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <table width={"100%"}>
                                <tbody>
                                    <tr>
                                        <td colSpan={4} style={{ textAlign: "center", fontWeight: 'bold' }}>Inclusos no local</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "center", width: "25%" }}>Tipo</td>
                                        <td style={{ textAlign: "center", width: "25%" }}>Total</td>
                                        <td style={{ textAlign: "center", width: "25%", backgroundColor: "#EEFFFF" }}>Presentes</td>
                                        <td style={{ textAlign: "center", width: "25%", backgroundColor: "#FFEEFF" }}>Ausentes</td>
                                    </tr>
                                    {dados["evento"] !== undefined ? dados["evento"].map((elemento, index) => (
                                        <tr>
                                            <td style={{ textAlign: "center" }}>{elemento.tipoparticipante}</td>
                                            <td style={{ textAlign: "center" }}>{elemento.total}</td>
                                            <td style={{ textAlign: "center", backgroundColor: "#EEFFFF" }}>{elemento.presentes}</td>
                                            <td style={{ textAlign: "center", backgroundColor: "#FFEEFF" }}>{elemento.total - elemento.presentes}</td>
                                        </tr>
                                    )) : null}
                                    {(dados["evento"] !== undefined && dados["evento"].length > 1) ? <tr style={{ backgroundColor: "#EEEEEE" }}>
                                        <td style={{ textAlign: "center" }}>Total</td>
                                        <td style={{ textAlign: "center" }}>{totalEvento}</td>
                                        <td style={{ textAlign: "center" }}>{presentesEvento}</td>
                                        <td style={{ textAlign: "center" }}>{ausentesEvento}</td>
                                    </tr> : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}